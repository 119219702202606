define("web-app-frontend/templates/resource-center/tips/component-selection", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.0",
          "loc": {
            "source": null,
            "start": {
              "line": 73,
              "column": 12
            },
            "end": {
              "line": 75,
              "column": 12
            }
          },
          "moduleName": "web-app-frontend/templates/resource-center/tips/component-selection.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("i");
          dom.setAttribute(el1, "class", "fa fa-chevron-left mr5");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("Previous Tip: Reading a Data Sheet\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.0",
          "loc": {
            "source": null,
            "start": {
              "line": 80,
              "column": 12
            },
            "end": {
              "line": 82,
              "column": 12
            }
          },
          "moduleName": "web-app-frontend/templates/resource-center/tips/component-selection.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              Next Tip: Do BOMs Right");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("i");
          dom.setAttribute(el1, "class", "fa fa-chevron-right ml5");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes", "wrong-type"]
        },
        "revision": "Ember@2.4.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 95,
            "column": 0
          }
        },
        "moduleName": "web-app-frontend/templates/resource-center/tips/component-selection.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "hero-area tips");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "row");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "col-md-2 text-center");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "icon-circle-bordered small");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("i");
        dom.setAttribute(el6, "class", "icon-bulb-orange font-65");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "col-md-10 sm-text-center");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h6");
        dom.setAttribute(el5, "class", "mt10");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(" ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("span");
        dom.setAttribute(el6, "class", "ml5 mr5");
        var el7 = dom.createTextNode("/");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(" ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h1");
        dom.setAttribute(el5, "class", "h2 montserrat mt10");
        var el6 = dom.createTextNode("Component Selection");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row mt60");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-md-8");
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h3");
        dom.setAttribute(el4, "class", "article-heading");
        var el5 = dom.createTextNode("Good prototyping is all about minimizing variables. Working with a partner like Screaming Circuits reduces the risks during the assembly process, but you must also be confident in the parts. Depending on how your PCB components are sourced, it can have major impacts on your PCB assembly project – both at each individual stage, and with the overall project.");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4, "class", "article-subhead");
        var el5 = dom.createTextNode("The Benefits of a Common Parts Library");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "article-p");
        var el5 = dom.createTextNode("With so many parts on a board, checking every component takes time and opens the risk of error, so a best practice is to leverage a library of common parts that have already been vetted within your organization. Beyond reducing design risk, spec'ing readily-available parts improves the manufacturability of your design and its lifecycle forecast.");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "article-p");
        var el5 = dom.createTextNode("You can check parts not vetted by your organization using the ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5, "href", "https://octopart.com/common-parts-library");
        dom.setAttribute(el5, "target", "_blank");
        var el6 = dom.createTextNode("Common Parts Library");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("–an open source project that takes advantage of the network effect of thousands of engineers to standardize certain parts that are trusted by the community and have good availability.");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4, "class", "article-subhead");
        var el5 = dom.createTextNode("Parts Life Cycle and Availability Issues");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "article-p");
        var el5 = dom.createTextNode("Whether you plan to manufacture your prototype in the U.S. or offshore, thinking about parts life cycle before design will save you time and money down the road.");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "article-p");
        var el5 = dom.createTextNode("Working with parts that have a good lifecycle forecast will save you from halting production to redesign a product with parts that went end-of-life. Likewise, thinking about parts availability is essential for offshore manufacturing. The parts you spec for your PCB may be available here, but will they be available at your offshore manufacturing site? Check before you design.");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4, "class", "article-subhead");
        var el5 = dom.createTextNode("Good Sourcing Practices");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "article-p");
        var el5 = dom.createTextNode("The best way to remove the variables in PCB parts is by sourcing from trusted distributors who buy from legitimate manufacturers and inspect for counterfeits. In our experience with sourcing parts, we've found that buying from proven parts distributors is worth the cost premium–if there is one–because avoiding counterfeit or grey market parts saves money and time in the long-run.");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4, "class", "article-subhead");
        var el5 = dom.createTextNode("Sourcing Parts through Screaming Circuits");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "article-p");
        var el5 = dom.createTextNode("With our Full or Partial Turn-Key service options, you can source a specific portion (or all) of your parts through Screaming Circuits. Having us source the parts can save you significant amounts of time, and helps you remove the burden of having to determine which parts to use during the procurement process.  Over the years, our purchasing team has formed long-standing relationships with some of the largest, most well-known and trustworthy electronic component distributors in the US.");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "article-p");
        var el5 = dom.createTextNode("By choosing to have us handle the sourcing of your parts, your range of access to component distributors becomes vastly extended via our well-established network. Along with this significant advantage, it can also help to ensure there will be fewer potential delays, inaccuracies, and obstructions to the completion of your project:");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        dom.setAttribute(el4, "class", "article-list fa-ul");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createElement("i");
        dom.setAttribute(el6, "class", "fa-li fa fa-check");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("Enhanced Security – we only buy components from reputable sources, which means you can count on the fact that your parts originated from a genuine source, were not mishandled, and have authentic traceability from a US-based distributor.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createElement("i");
        dom.setAttribute(el6, "class", "fa-li fa fa-check");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("Fewer Potential Issues – over the years, our purchasing team has encountered almost all of the potential hurdles and impediments for a component sourcing effort. We rely on this experience to procure your parts quickly while avoiding the common pitfalls and mistakes others may overlook and get snagged on.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createElement("i");
        dom.setAttribute(el6, "class", "fa-li fa fa-check");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("Prevents Delays – Screaming Circuits needs all of the necessary kit components here at our facility before we can start building your boards. Sometimes a customer tells us they will ship their components to us asap, but then they get sidetracked with another commitment and the parts don’t arrive to our facility on time. When we handle the ordering, we know exactly what day the parts will arrive - allowing us to count on a predetermined production schedule, which increases the likelihood that your boards will be shipped on time.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createElement("i");
        dom.setAttribute(el6, "class", "fa-li fa fa-check");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("Direct Supplier Communication – Screaming Circuits has well-established connections and lines of communication with our component distribution network. This allows for a prompt and uncomplicated ordering process when purchasing parts, whereas this process can sometimes be time-consuming and confusing for those without any direct-to-supplier relationships.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createElement("i");
        dom.setAttribute(el6, "class", "fa-li fa fa-check");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("High Levels of Control – When we have more flexibility and can choose the best strategy for sourcing, this allows us to be certain that we will have the components we need when we need them, and that they will be packaged in an efficient manner compliant to our assembly equipment and processes.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createElement("i");
        dom.setAttribute(el6, "class", "fa-li fa fa-check");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("Eliminates Unnecessary Prior Build Processing – When parts are sourced directly from our trusted distributors, we know when they will arrive, and that they will be in proper packaging. Our expertise with previous projects allows for the elimination of some processing operations, which can help to save time.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createElement("i");
        dom.setAttribute(el6, "class", "fa-li fa fa-check");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("Faster Parts Audit – The faster we can audit your parts kit after it arrives, the sooner we can get started assembling your boards. When we source your parts, we automatically order the appropriate amount of extra components to account for attrition and we do not have to spend as much time counting parts. Furthermore, we can be sure all parts will not have to be pre-baked to remove moisture from components due to incorrect packaging – a process which can add to the job’s audit and setup times. ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-md-3 col-md-offset-1");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4, "class", "article-subhead");
        var el5 = dom.createTextNode("Related Resources");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        dom.setAttribute(el4, "class", "article-resources");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5, "class", "tips");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5, "class", "tips");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5, "class", "tutorial");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5, "class", "services");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        dom.setAttribute(el5, "class", "services");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-md-12");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("hr");
        dom.setAttribute(el4, "class", "mt40");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "row");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "col-md-6 text-left");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h6");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "col-md-6 text-right");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h6");
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode(" ");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment(" container ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1, 1, 3, 1]);
        var element1 = dom.childAt(fragment, [2]);
        var element2 = dom.childAt(element1, [1, 3, 3]);
        var element3 = dom.childAt(element1, [3, 1, 3]);
        var morphs = new Array(12);
        morphs[0] = dom.createMorphAt(element0, 0, 0);
        morphs[1] = dom.createMorphAt(element0, 4, 4);
        morphs[2] = dom.createMorphAt(dom.childAt(element2, [1]), 0, 0);
        morphs[3] = dom.createMorphAt(dom.childAt(element2, [3]), 0, 0);
        morphs[4] = dom.createMorphAt(dom.childAt(element2, [5]), 0, 0);
        morphs[5] = dom.createMorphAt(dom.childAt(element2, [7]), 0, 0);
        morphs[6] = dom.createMorphAt(dom.childAt(element2, [9]), 0, 0);
        morphs[7] = dom.createMorphAt(dom.childAt(element2, [11]), 0, 0);
        morphs[8] = dom.createMorphAt(dom.childAt(element3, [1, 1]), 1, 1);
        morphs[9] = dom.createMorphAt(dom.childAt(element3, [3, 1]), 1, 1);
        morphs[10] = dom.createMorphAt(fragment, 6, 6, contextualElement);
        morphs[11] = dom.createMorphAt(fragment, 8, 8, contextualElement);
        return morphs;
      },
      statements: [["inline", "link-to", ["Resource Center", "resource-center.index"], ["class", "white underline"], ["loc", [null, [10, 25], [10, 102]]]], ["inline", "link-to", ["Tips & Tricks", "resource-center.tips"], ["class", "white underline"], ["loc", [null, [10, 134], [10, 208]]]], ["inline", "link-to", ["Reading a Data Sheet", "resource-center.tips.reading-data-sheet"], [], ["loc", [null, [57, 25], [57, 101]]]], ["inline", "link-to", ["Counterfeiting", "resource-center.tips.counterfeits"], [], ["loc", [null, [58, 25], [58, 89]]]], ["inline", "link-to", ["Tutorial: How to Pack Your Parts", "resource-center.tutorials.packing-parts"], [], ["loc", [null, [59, 29], [59, 117]]]], ["inline", "link-to", ["Partners", "about.partners"], [], ["loc", [null, [60, 12], [60, 51]]]], ["inline", "link-to", ["Turn-key", "resource-center.services.turn-key"], [], ["loc", [null, [61, 29], [61, 87]]]], ["inline", "link-to", ["Parts Sourcing", "resource-center.services.parts-sourcing"], [], ["loc", [null, [62, 29], [62, 99]]]], ["block", "link-to", ["resource-center.tips.reading-data-sheet"], [], 0, null, ["loc", [null, [73, 12], [75, 24]]]], ["block", "link-to", ["resource-center.tips.do-boms"], [], 1, null, ["loc", [null, [80, 12], [82, 24]]]], ["inline", "marketing-section-links", [], ["class", "mt60", "indexName", "tips"], ["loc", [null, [92, 0], [92, 57]]]], ["content", "marketing-footer", ["loc", [null, [94, 0], [94, 20]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});